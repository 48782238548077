import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
} from '@mui/material/'

export const MyAccessAuditModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearAuditMessage }}
    >
      <Fade in={props.open}>
        {props.auditMessage === '' ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              <div>
                {props.auditMessage === 'Success' ? (
                  <div>
                    <div style={{ paddingBottom: '10px' }}>
                      Access audit sheet successfully created.
                    </div>
                    <br />
                    <div style={{ paddingBottom: '10px' }}>
                      <a
                        href={props.sheetUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.sheetName}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>Audit creation failed. {props.auditMessage}</div>
                )}
                <br />
                <Button variant="contained" onClick={props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Fade>
    </Dialog>
  )
}
