export const CLEAR_DHCP_MESSAGE = 'CLEAR_DHCP_MESSAGE'
export const DHCP_GENERATION = 'DHCP_GENERATION'
export const DNS_GENERATION = 'DNS_GENERATION'
export const DOWNLOAD_GROUP_SHEET = 'DOWNLOAD_GROUP_SHEET'
export const DOWNLOAD_IP_PHONE_DATA = 'DOWNLOAD_IP_PHONE_DATA'
export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS'
export const GET_GROUP_NAMES = 'GET_GROUP_NAMES'
export const SET_RESULT_MESSAGE = 'SET_RESULT_MESSAGE'
export const TOGGLE_STATUS_OPEN = 'TOGGLE_STATUS_OPEN'
export const GET_WORK_ORDER_OPTIONS = 'GET_WORK_ORDER_OPTIONS'
export const POST_MAXIMO = 'POST_MAXIMO'
export const GET_ACTIVE_SHEETS = 'GET_ACTIVE_SHEETS'
export const GET_WORKSPACES = 'GET_WORKSPACES'
export const GET_MAXIMO_BULK_LOCATIONS = 'GET_MAXIMO_BULK_LOCATIONS'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const CREATE_ACCESS_AUDIT = 'CREATE_ACCESS_AUDIT'
export const CLEAR_AUDIT_MESSAGE = 'CLEAR_AUDIT_MESSAGE'
