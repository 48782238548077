import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Grid,
  Typography,
  CardActions,
  CardContent,
} from '@mui/material'
import { MyAccessAuditModal } from './MyAccessAuditModal'
import {
  createAccessAudit,
  clearAuditMessage,
} from '../../../store/AdditionalTools/actionCreator'
import apiConfig from '../../../config/apiConfig'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

export class MyAccessAuditTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      auditMessage: '',
      loading: false,
      sheetName: '',
      sheetUrl: '',
      statusOpen: false,
    }
    this.baseState = this.state
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    initializeFireflySDK()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auditMessage !== nextProps.auditMessage) {
      this.setState({
        auditMessage: nextProps.auditMessage,
        loading: false,
      })
    }
    if (this.props.sheetName !== nextProps.sheetName) {
      this.setState({
        sheetName: nextProps.sheetName,
      })
    }
    if (this.props.sheetUrl !== nextProps.sheetUrl) {
      this.setState({
        sheetUrl: nextProps.sheetUrl,
      })
    }
    if (this.props.statusOpen !== nextProps.statusOpen) {
      this.setState({
        statusOpen: nextProps.statusOpen,
      })
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Access Audit Tab' },
      user: { id: userInfo.email },
    })
  }

  handleOnSubmit(event) {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Access Audit - Create' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    var createAudit = true
    this.props.createAccessAudit(createAudit)
    this.setState({ statusOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  render() {
    let modalTitle
    if (this.state.auditMessage === 'Success') {
      modalTitle = 'Success'
    } else {
      modalTitle = 'Failed'
    }
    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom sx={{ fontSize: '16px' }}>
              Press button to create access audit.
            </Typography>
            <br />
            <br />
          </Grid>
        </CardActions>
        <form onSubmit={this.handleOnSubmit}>
          <Grid container spacing={1} sx={{ margin: '-4px' }}>
            <Grid container item xs={12} md={6} lg={3}>
              <CardActions
                style={{
                  width: '90%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Create
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <MyAccessAuditModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          title={modalTitle}
          auditMessage={this.state.auditMessage}
          sheetName={this.state.sheetName}
          sheetUrl={this.state.sheetUrl}
          clearAuditMessage={this.props.clearAuditMessage}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auditMessage: state.additionalTools.auditMessage,
    sheetName: state.additionalTools.sheetName,
    sheetUrl: state.additionalTools.sheetUrl,
    statusOpen: state.additionalTools.statusOpen,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  createAccessAudit,
  clearAuditMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccessAuditTab)
