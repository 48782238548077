import React from 'react'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import {
  Grid,
  Typography,
  Card,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
} from '@mui/material'
import SsGroupsAuditTab from './SsGroupsAudit/SsGroupsAuditTab'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'
import DhcpExcelGenerationTab from './DhcpExcelGeneration/DhcpExcelGenerationTab'
import DnsExcelGenerationTab from './DnsExcelGeneration/DnsExcelGenerationTab'
import IpPhoneExportTab from './IpPhoneExport/IpPhoneExportTab'
import MaximoWorkOrderTab from './MaximoWorkOrder/MaximoWorkOrderTab'
import MyAccessAuditTab from './MyAccessAudit/MyAccessAuditTab'

export class AdditionalTools extends React.Component {
  constructor(props) {
    super(props)
    this.availableTabs = [
      {
        name: 'DHCP EXCEL GENERATION',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN'],
      },
      {
        name: 'DNS EXCEL GENERATION',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN'],
      },
      {
        name: 'SMARTSHEET GROUPS AUDIT',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN'],
      },
      {
        name: 'IP PHONE DATA EXPORT',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-CAD'],
      },
      {
        name: 'MAXIMO WORK ORDERS',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN'],
      },
      {
        name: 'MYACCESS AUDIT',
        requiredAdGroups: ['APP-ZIPTIE-ADMIN'],
      },
    ]
    this.state = {
      authorizedTabs: [],
      loading: true,
      selectedTab: 0,
    }
    initializeFireflySDK()
    this.handleTabChange = this.handleTabChange.bind(this)
    this.dialogBox = React.createRef()
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Additional Tools',
          pageName: 'Additional Tools',
          pageType: 'Additional Tools',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Additional Tools' },
      user: { id: userInfo.email },
    })

    const authorizedTabs = this.availableTabs.filter(function (tab) {
      return (
        tab.requiredAdGroups.filter((group) =>
          userInfo.memberOf.includes(group),
        ).length > 0 || tab.requiredAdGroups.length === 0
      )
    })
    this.setState({
      authorizedTabs: authorizedTabs,
      loading: false,
      selectedTab: authorizedTabs[0].name,
    })
  }

  handleTabChange(event, newTab) {
    this.setState({
      selectedTab: newTab,
    })
  }

  render() {
    const { classes, userInfo } = this.props

    let tabLabels = []
    this.state.authorizedTabs.forEach((tab) => {
      let tabLabel = <Tab key={tab.name} label={tab.name} value={tab.name} />
      tabLabels.push(tabLabel)
    })

    return (
      <div>
        <HeaderTitle title="Additional Tools" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Card style={{ height: '75vh' }}>
                  <AppBar position="static">
                    <Tabs
                      value={this.state.selectedTab}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {tabLabels}
                    </Tabs>
                  </AppBar>
                  {this.state.selectedTab === 'DHCP EXCEL GENERATION' &&
                    userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') && (
                      <DhcpExcelGenerationTab classes={classes} />
                    )}
                  {this.state.selectedTab === 'DNS EXCEL GENERATION' &&
                    userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') && (
                      <DnsExcelGenerationTab classes={classes} />
                    )}
                  {this.state.selectedTab === 'SMARTSHEET GROUPS AUDIT' &&
                    userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') && (
                      <SsGroupsAuditTab classes={classes} />
                    )}
                  {this.state.selectedTab === 'IP PHONE DATA EXPORT' &&
                    (userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
                      userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-CAD')) && (
                      <IpPhoneExportTab classes={classes} />
                    )}
                  {this.state.selectedTab === 'MAXIMO WORK ORDERS' &&
                    userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') && (
                      <MaximoWorkOrderTab classes={classes} />
                    )}
                  {this.state.selectedTab === 'MYACCESS AUDIT' &&
                    userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') && (
                      <MyAccessAuditTab classes={classes} />
                    )}
                </Card>
              </Grid>
            </div>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalTools)
