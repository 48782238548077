import React from 'react'
import { PropTypes, shape, string, object } from 'prop-types'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import { getProjects } from '../../store/HomePage/actionCreator'
import rndLogo from '../../images/RolloutDeployment-NoBack.png'
import MUIDataTable from 'mui-datatables'
import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'
import { getDomainName } from '../Utils/GetDomainName'

const styles = {
  button: {
    color: '#191919',
    borderColor: '#191919',
  },
  h2: {
    margin: 0,
    fontWeight: 400,
  },
  logoDiv: {
    height: 110,
    width: 110,
  },
  rndLogoStyle: {
    margin: 'auto',
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  span: {
    fontWeight: 800,
  },
}

class HomePage extends React.Component {
  static propTypes = {
    classes: object,
    accessToken: PropTypes.string,
    userInfo: shape({
      email: string,
    }),
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      projectsList: [],
      loading: false,
      anchorElTools: null,
      anchorElSlo: null,
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    await this.props.getProjects()
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Home Page',
          pageName: 'Home Page',
          pageType: 'Home Page',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Home Page' },
      user: { id: userInfo.email },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ loading: true })
    if (nextProps.projectsList.length) {
      this.setState({
        projectsList: JSON.parse(JSON.stringify(nextProps.projectsList)),
      })
      this.setState({ loading: false })
    }
  }

  handleClickTools = (event) => {
    this.setState({ anchorElTools: event.currentTarget })
  }

  handleCloseTools = () => {
    this.setState({ anchorElTools: null })
  }

  handleClickSlo = (event) => {
    this.setState({ anchorElSlo: event.currentTarget })
  }

  handleCloseSlo = () => {
    this.setState({ anchorElSlo: null })
  }

  expandedRowMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: '#e0e0e0',
              minHeight: '8px',
            },
          },
        },
      },
    })

  render() {
    // SLO links are not available anymore so commenting until we get new working links
    // const { anchorElSlo } = this.state
    const { anchorElTools } = this.state
    const { button, h2, logoDiv, rndLogoStyle, span } = styles
    let projects = []
    if (
      this.state.projectsList.length > 0 &&
      this.state.projectsList !== 'No Projects Found!'
    ) {
      const columns = [
        {
          label: 'T#',
          name: 'location_id',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Health',
          name: 'health',
          options: {
            filterType: 'multiselect',
            hint: 'Support: Red/Yellow -> send to R&D, Blue/Green -> normal support',
          },
        },
        {
          label: 'Project | Scope Name',
          name: 'project_name',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Execution Period Start Date',
          name: 'start_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Execution Period End Date',
          name: 'end_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Scheduled Status',
          name: 'scheduled_status',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Scope Status',
          name: 'status',
          options: {
            filterType: 'multiselect',
            hint: 'Scope Status: Complete - R&D work is complete; In-Progress - work not yet complete; Delayed - work is planned but is behind schedule',
          },
        },
        {
          label: 'Work Start Date',
          name: 'work_start_date',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Work End Date',
          name: 'work_end_date',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Hours | Time Frame of Work',
          name: 'work_time_frame',
          options: { filterType: 'multiselect' },
        },
        {
          label: '% Complete',
          name: 'percentage_complete',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Conditional Signoff Notes',
          name: 'conditional_sign_off_notes',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Execution Period',
          name: 'period',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Delay Reason',
          name: 'delay_reason',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Notes',
          name: 'notes',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Turnover Date',
          name: 'turnover',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Go Live Date',
          name: 'go_live_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor PM',
          name: 'vendor_project_manager',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor Visit Dates',
          name: 'vendor_visit_dates',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Construction PM',
          name: 'construction_pm',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'TTS Project Manager',
          name: 'tts_project_manager',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Consolidation Vendor',
          name: 'consolidation_vendor',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor Responsible for Scope',
          name: 'deployment_support_vendor',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'TTS Execution Team',
          name: 'tts_project_team',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Smartsheet URL',
          name: 'sheet_url',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Smartsheet Name',
          name: 'sheet_name',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Project Summary',
          name: 'project_summary',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Store Action Required',
          name: 'store_action_required',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Hardware Arriving',
          name: 'hardware_arriving',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Access Areas',
          name: 'access_areas',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Time to Task',
          name: 'time_to_task',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Time of Day',
          name: 'time_of_day',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Question Resource',
          name: 'question_resource',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Question Address',
          name: 'question_address',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Project has POS Scope',
          name: 'pos_scope',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendors will have control room PIN',
          name: 'vendors_have_pin',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
      ]
      const projectDetailsColumns = [
        {
          label: 'T#',
          name: 'location_id',
        },
        {
          label: 'Health',
          name: 'health',
          options: {
            hint: 'Support: Red/Yellow -> send to R&D, Blue/Green -> normal support',
          },
        },
        {
          label: 'Project | Scope Name',
          name: 'project_name',
        },
        {
          label: 'Execution Period',
          name: 'period',
        },
        {
          label: 'Scope Status',
          name: 'status',
          options: {
            hint: 'Scope Status: Complete - R&D work is complete; In-Progress - work not yet complete; Delayed - work is planned but is behind schedule',
          },
        },
        {
          label: '% Complete',
          name: 'percentage_complete',
        },
        {
          label: 'Scheduled Status',
          name: 'scheduled_status',
        },
        {
          label: 'Conditional Signoff Notes',
          name: 'conditional_sign_off_notes',
        },
        {
          label: 'Delay Reason',
          name: 'delay_reason',
        },
      ]
      const projectTimelinesColumns = [
        {
          label: 'Work Start Date',
          name: 'work_start_date',
        },
        {
          label: 'Work End Date',
          name: 'work_end_date',
        },
        {
          label: 'Execution Period Start Date',
          name: 'start_date',
        },
        {
          label: 'Execution Period End Date',
          name: 'end_date',
        },
        {
          label: 'Hours | Time Frame of Work',
          name: 'work_time_frame',
        },
        {
          label: 'Turnover Date',
          name: 'turnover',
        },
        {
          label: 'Go Live Date',
          name: 'go_live_date',
        },
        {
          label: 'Vendor Visit Dates',
          name: 'vendor_visit_dates',
        },
      ]
      const contactInformationColumns = [
        {
          label: 'Vendor PM',
          name: 'vendor_project_manager',
        },
        {
          label: 'TTS Execution Team',
          name: 'tts_project_team',
        },
        {
          label: 'TTS Project Manager',
          name: 'tts_project_manager',
        },
        {
          label: 'Construction PM',
          name: 'construction_pm',
        },
        {
          label: 'Consolidation Vendor',
          name: 'consolidation_vendor',
        },
        {
          label: 'Vendor Responsible for Scope',
          name: 'deployment_support_vendor',
        },
      ]
      const miscColumns = [
        {
          label: 'Smartsheet Link',
          name: 'sheet_name',
        },
        {
          label: 'Notes',
          name: 'notes',
        },
      ]
      const projectSummaryColumns = [
        {
          label: 'Project Summary',
          name: 'project_summary',
        },
        {
          label: 'Store Action Required',
          name: 'store_action_required',
        },
        {
          label: 'Hardware Arriving',
          name: 'hardware_arriving',
        },
        {
          label: 'Access Areas',
          name: 'access_areas',
        },
        {
          label: 'Time to Task',
          name: 'time_to_task',
        },
        {
          label: 'Time of Day',
          name: 'time_of_day',
        },
        {
          label: 'Question Resource',
          name: 'question_resource',
        },
        {
          label: 'Question Address',
          name: 'question_address',
        },
        {
          label: 'Project has POS Scope',
          name: 'pos_scope',
        },
        {
          label: 'Vendors will have control room PIN',
          name: 'vendors_have_pin',
        },
      ]
      projects = (
        <MUIDataTable
          title="R&D Active Projects"
          columns={columns}
          data={this.state.projectsList}
          options={{
            responsive: 'vertical',
            rowsPerPageOptions: [10, 50, 100],
            expandableRows: true,
            jumpToPage: true,
            selectableRows: 'none',
            renderExpandableRow: (rowData) => {
              var projectDetailsData = [
                {
                  location_id: rowData[0],
                  health: rowData[1],
                  project_name: rowData[2],
                  period: rowData[12],
                  status: rowData[6],
                  percentage_complete: rowData[10],
                  scheduled_status: rowData[5],
                  conditional_sign_off_notes: rowData[11],
                  delay_reason: rowData[13],
                },
              ]
              var projectTimelinesData = [
                {
                  work_start_date: rowData[7],
                  work_end_date: rowData[8],
                  start_date: rowData[3],
                  end_date: rowData[4],
                  work_time_frame: rowData[9],
                  turnover: rowData[15],
                  go_live_date: rowData[16],
                  vendor_visit_dates: rowData[18],
                },
              ]
              var contactInformationData = [
                {
                  vendor_project_manager: rowData[17],
                  tts_project_team: rowData[23],
                  tts_project_manager: rowData[20],
                  construction_pm: rowData[19],
                  consolidation_vendor: rowData[21],
                  deployment_support_vendor: rowData[22],
                },
              ]
              var miscData = [
                {
                  sheet_name: (
                    <a
                      href={rowData[24]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rowData[25]}
                    </a>
                  ),
                  notes: rowData[14],
                },
              ]
              var projectSummaryData = [
                {
                  project_summary: rowData[26],
                  store_action_required: rowData[27],
                  hardware_arriving: rowData[28],
                  access_areas: rowData[29],
                  time_to_task: rowData[30],
                  time_of_day: rowData[31],
                  question_resource: rowData[32],
                  question_address: rowData[33],
                  pos_scope: rowData[34],
                  vendors_have_pin: rowData[35],
                },
              ]
              return (
                <TableRow>
                  <TableCell colSpan={rowData.length}>
                    <Box sx={{ border: 3 }}>
                      <ThemeProvider theme={this.expandedRowMuiTheme()}>
                        <div>
                          <div>
                            <MUIDataTable
                              title="Project Details"
                              columns={projectDetailsColumns}
                              data={projectDetailsData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Project Timelines"
                              columns={projectTimelinesColumns}
                              data={projectTimelinesData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Contact Information"
                              columns={contactInformationColumns}
                              data={contactInformationData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Project Summary"
                              columns={projectSummaryColumns}
                              data={projectSummaryData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Misc"
                              columns={miscColumns}
                              data={miscData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                        </div>
                      </ThemeProvider>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      projects = this.state.projectsList
    }

    const projectPanel = <div>{projects}</div>
    const isExternalDomain =
      getDomainName() !== apiConfig.auth.externalDomain ? false : true
    return (
      <div>
        <HeaderTitle title="Home" />
        <Grid container spacing={10}>
          <Grid item xs={12} style={{ margin: 10, paddingRight: 0 }}>
            <br />
            <Grid
              container
              spacing={8}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item md={1}>
                <div style={logoDiv}>
                  <img
                    alt="R&D logo"
                    src={rndLogo}
                    className={'rndLogo'}
                    style={rndLogoStyle}
                  />
                </div>
              </Grid>
              <Grid
                item
                md={5}
                className="missionStatementDiv"
                sx={{ textAlign: 'center' }}
              >
                <h2 className="missionStatementText" style={h2}>
                  With the help from our partners, we make Target the best in
                  retail by&nbsp;
                  <span className="missionStatementBold" style={span}>
                    delivering technology
                  </span>
                  &nbsp;for our Guests and Team Members with&nbsp;
                  <span className="missionStatementBold" style={span}>
                    minimal disruption
                  </span>
                  . We&nbsp;
                  <span className="missionStatementBold" style={span}>
                    focus on Guests
                  </span>
                  &nbsp;over projects and over process.
                </h2>
              </Grid>
              {!isExternalDomain ? (
                <Grid item md={1}>
                  <Grid item>
                    <Button
                      aria-controls="home-page"
                      aria-haspopup="true"
                      variant="outlined"
                      sx={button}
                      onClick={this.handleClickTools}
                    >
                      External Tools
                    </Button>
                    <Menu
                      id="home-page"
                      anchorEl={anchorElTools}
                      keepMounted
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElTools)}
                      onClose={this.handleCloseTools}
                    >
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://app.smartsheet.com/sheets/rCXxJ8ChvfWG38Gc7Jq5mcXQqfVrVC3CH8fccPq1?view=grid"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Automation Validation Grid - R&D Automated Testing
                        Documentation
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://app.smartsheet.com/sheets/HH76JGRwRmwcHcmXvjfc7wJG98rGCc2MmQHxp4c1?view=grid"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Device Configuration Grid - R&D Device Configuration
                        Documentation
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://visualize.target.com/d/isuISJIWz/guest-kiosk-status?tab=queries&orgId=1&refresh=15m&var-StoreNum=76&var-excluded_serials=All"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MAC Grafana Dashboard - MAC Kiosk health
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://mickra.prod.target.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Mickra - Endpoint device information
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://saml.iam.target.com/affwebservices/public/saml2sso?SPID=https://saml-l2e7y32y.gc1.mist.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Mist AP Portal - Mist access point information
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://nps.prod.target.com//"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Network Programmability Services (NPS)
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://picard.prod.target.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Picard (New POSCentral) - POS device information
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://app.smartsheet.com/dashboards/wJ4hF5J24pwxvmFgwX94XQ2vHq2XV7PXHf8j8j91/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        R&D Software Engineering Portal - Automation
                        Documentation
                      </MenuItem>
                      <MenuItem
                        component="a"
                        onClick={this.handleCloseTools}
                        href="https://visualize.target.com/d/000009165/store-stack-health?orgId=24&refresh=5m"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Store Stack Health Dashboard - Network device health
                        information
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {/* SLO links are not available anymore so commenting until we get new working links
              <Grid item md={1}>
                <Grid item>
                  <Button
                    aria-controls="home-page"
                    aria-haspopup="true"
                    variant="outlined"
                    sx={button}
                    onClick={this.handleClickSlo}
                  >
                    R&D SLOs
                  </Button>
                  <Menu
                    id="home-page"
                    anchorEl={anchorElSlo}
                    keepMounted
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElSlo)}
                    onClose={this.handleCloseSlo}
                  >
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI10111329/group/Bailiwick-Inventory-Update"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bailiwick Inventory Update - Bailiwick hardware inventory
                      updating
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI11571833/group/catcher"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CatcheR - R&D active project information caching
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI09762979/group/nightswatch"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NightsWatch - 24hr power required device monitoring
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI11353561/group/rapid"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      rAPId - back-end for in-store work automated testing
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI04435988/group/rollout_deployment_projects"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      R&D Projects API - active in-store work visibility
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI10031202/group/RollCall-locations"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Roll Call - R&D Master Locations List updating
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={this.handleCloseSlo}
                      href="https://rtpsandbox.dev.target.com/slo/application/CI02877868/group/ziptie"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ziptie - R&D UI
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid> */}
            </Grid>
            <br />
            {this.state.loading ? (
              <Grid
                container
                spacing={16}
                justifyContent={'center'}
                className="progress"
              >
                <CircularProgress />
                <Typography className="loading">
                  &nbsp;Loading R&D Active Projects...
                </Typography>
              </Grid>
            ) : (
              <Grid container spacing={16} sx={{ justifyContent: 'center' }}>
                <Grid item xs={12}>
                  {this.state.projectsList.length > 0 ? (
                    typeof this.state.projectsList[0] === 'string' ? (
                      <Typography variant="h4">
                        {this.state.projectsList}
                      </Typography>
                    ) : (
                      projectPanel
                    )
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout

  return {
    headerTitle: headerTitle,
    accessToken: state.auth.accessToken,
    userInfo: state.auth.userInfo,
    projectsList: state.homePage.projectsList,
  }
}

const mapDispatchToProps = {
  getProjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
