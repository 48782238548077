import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  TextField,
} from '@mui/material/'

export const DeviceConfigurationConfirmModal = (props) => {
  let location = ''
  if (props.confirmKey === 'Remove') {
    location = 'several locations'
  } else {
    location = props.confirmKey
  }
  const formattedMessage = (
    <div>
      <p>You are attempting to remove devices from {location}.</p>
      <p>
        To confirm that you wish to do this, please type{' '}
        <b>{props.confirmKey}</b> into the input below.
      </p>
    </div>
  )

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearMessage }}
    >
      <Fade in={props.open}>
        <div>
          <DialogTitle>Confirm Removal</DialogTitle>
          <DialogContent>
            <div>
              <div style={{ paddingBottom: '10px' }}>{formattedMessage}</div>
              <br />
              <div style={{ paddingBottom: '10px' }}>
                <form onSubmit={props.submitConfirm}>
                  <TextField
                    type="text"
                    variant="standard"
                    value={props.confirmInput}
                    onChange={(event) =>
                      props.handleConfirmInput(event.target.value)
                    }
                    placeholder={props.confirmKey}
                    required
                    style={{ paddingBottom: '10px' }}
                  />
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    onClick={props.handleConfirmClose}
                    style={{
                      marginRight: '5px',
                      backgroundColor: '#e0e0e0',
                      color: '#000000de',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!props.confirmButtonEnabled}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </DialogContent>
        </div>
      </Fade>
    </Dialog>
  )
}
