import * as types from './actionType'

export const initialState = {
  dhcpErrorMessage: '',
  dnsErrorMessage: '',
  dnsErrorOpen: false,
  dnsFileData: '',
  dnsFileName: '',
  fileData: '',
  fileName: '',
  groupsAndMembers: [],
  ipFileData: '',
  ipFileName: '',
  resultMessage: '',
  smartsheetGroups: [],
  statusOpen: false,
  xlsxFileData: '',
  xlsxFileName: '',
  workspaces: [],
  activeSheets: [],
  bulkLocations: [],
  auditMessage: '',
  sheetName: '',
  sheetUrl: '',
}

export default function additionalToolsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.CLEAR_DHCP_MESSAGE: {
      const newState = {
        ...state,
        dhcpErrorMessage: '',
      }
      return newState
    }
    case types.DHCP_GENERATION: {
      const newState = {
        ...state,
        dhcpErrorMessage: action.payload.dhcpErrorMessage,
        xlsxFileData: action.payload.xlsxFileData,
        xlsxFileName: action.payload.xlsxFileName,
      }
      return newState
    }
    case types.DNS_GENERATION: {
      const newState = {
        ...state,
        dnsFileData: action.payload.fileData,
        dnsFileName: action.payload.fileName,
        dnsErrorMessage: action.payload.dnsErrorMessage,
        dnsErrorOpen: action.payload.dnsErrorOpen,
      }
      return newState
    }
    case types.DOWNLOAD_GROUP_SHEET: {
      const newState = {
        ...state,
        fileData: action.payload.fileData,
        fileName: action.payload.fileName,
        resultMessage: action.payload.resultMessage,
      }
      return newState
    }
    case types.DOWNLOAD_IP_PHONE_DATA: {
      const newState = {
        ...state,
        ipFileData: action.payload.ipFileData,
        ipFileName: action.payload.ipFileName,
        resultMessage: action.payload.resultMessage,
        statusOpen: action.payload.statusOpen,
      }
      return newState
    }
    case types.GET_GROUP_MEMBERS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            groupsAndMembers: action.payload,
          }
        : {
            ...state,
            groupsAndMembers: [action.payload],
          }
      return newState
    }
    case types.GET_GROUP_NAMES: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            smartsheetGroups: action.payload,
          }
        : {
            ...state,
            smartsheetGroups: [action.payload],
          }
      return newState
    }
    case types.SET_RESULT_MESSAGE: {
      const newState = {
        ...state,
        resultMessage: action.payload,
      }
      return newState
    }
    case types.TOGGLE_STATUS_OPEN: {
      const newState = {
        ...state,
        statusOpen: action.payload,
      }
      return newState
    }
    case types.GET_WORK_ORDER_OPTIONS: {
      const newState = {
        ...state,
        workOrderOptions: action.payload,
      }
      return newState
    }
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventsMessage: action.payload,
      }
      return newState
    }
    case types.POST_MAXIMO: {
      const newState = {
        ...state,
        fileData: action.payload.fileData,
        fileName: action.payload.fileName,
        eventsMessage: action.payload.eventsMessage,
        statusOpen: action.payload.statusOpen,
      }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    case types.GET_WORKSPACES: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            workspaces: action.payload,
          }
        : {
            ...state,
            workspaces: [action.payload],
          }
      return newState
    }
    case types.GET_MAXIMO_BULK_LOCATIONS: {
      const newState = {
        ...state,
        bulkLocations: action.payload,
      }
      return newState
    }
    case types.CREATE_ACCESS_AUDIT: {
      const newState = {
        ...state,
        auditMessage: action.payload.auditMessage,
        sheetName: action.payload.sheetName,
        sheetUrl: action.payload.sheetUrl,
        statusOpen: action.payload.statusOpen,
      }
      return newState
    }
    case types.CLEAR_AUDIT_MESSAGE: {
      const newState = {
        ...state,
        auditMessage: action.payload,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
